/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { WorldsWebModels } from '@/store/worldsWebModels/types'
const namespace: string = 'worldsWebModels';


export default class WorldsWebModelsPhotosPage extends Vue {
	@State('worldsWebModels') worldsWebModels: WorldsWebModels | undefined;
	@Getter('getModelsPhotos', {namespace}) getModelsPhotos: any;
	@Mutation('setItemPhoto', {namespace}) setItemPhoto: any;

	item: number = 0;
	fullSize(item: number) {
		this.setItemPhoto(item);
		this.$router.push({ name: 'worlds-web-models-photos-big-size' }).then(r => r);
	}
}
